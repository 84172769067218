.skills-list {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skills-list h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #007bff;
  text-align: center;
  line-height: 20px;
  color: white;
  font-weight: bold;
  transition: width 0.3s ease;
}

.full-page-section {
  width: 100%; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: #f0f0f0; /* Example background color */
  /* Additional styling as needed */
}

.section-page-section {
  padding: 20px; /* Adjust as needed */
  overflow: hidden; /* Ensure no content overflows */
}